import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, useTheme } from '@mui/material';

const Wedding = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/wedding/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  if (!content) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center' }}>{content.title}</Typography>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>

          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center' }}>{content.section1.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section1.paragraph1}</Typography>
          
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1253,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Bridal Hairstyle"
            src="/images/out/wedding_1.jpg"
          />
          
          <Typography variant="body1" sx={{ textAlign: 'center', my: 2, fontStyle: 'italic' }}>{content.section1.paragraph2}</Typography>

        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center' }}>{content.section2.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section2.paragraph1}</Typography>
          
          <Box
            component="img"
            sx={{
              width: '100%',
              maxWidth: 1254,
              margin: '0 auto',
              display: 'block',
              borderRadius: '10px'
            }}
            alt="Wedding Make Up"
            src="/images/out/wedding_2.jpg"
          />
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section1.paragraph3}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.section1.paragraph4}</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Wedding;
