import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Link, Table, TableBody, TableCell, TableContainer, TableRow, Paper, useTheme } from '@mui/material';
import { GoogleMap, LoadScript, Marker,InfoWindow  } from '@react-google-maps/api';

const Location = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [rowsOpeningTimes, setRowsOpeningTimes] = useState(null);
  const [infoWindowOpen, setInfoWindowOpen] = useState(true); 
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/location/${language}.json`);
      setContent(response.default);
    };
    loadContent();
  }, [language]);

  useEffect(() => {
    if (content) {
      const loadRows = () => {
        setRowsOpeningTimes([
          { day: content.opening_times.monday_name, time: content.opening_times.monday },
          { day: content.opening_times.tuesday_name, time: content.opening_times.tuesday },
          { day: content.opening_times.wednesday_name, time: content.opening_times.wednesday },
          { day: content.opening_times.thursday_name, time: content.opening_times.thursday },
          { day: content.opening_times.friday_name, time: content.opening_times.friday },
          { day: content.opening_times.saturday_name, time: content.opening_times.saturday }
        ]);
      };
      loadRows();
    }
  }, [content]);

  if (!content || !rowsOpeningTimes) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.title}</Typography>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.car.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.car.paragraph1}</Typography>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.public_transport.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.public_transport.from_zurich}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.public_transport.from_sargans}</Typography>
          <Link href={content.sbb_link} target="_blank" rel="noopener">
            <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2, color: theme.palette.divider }}>
              {content.public_transport.paragraph1}
            </Typography>
          </Link>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.public_transport.paragraph2}</Typography>
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <LoadScript googleMapsApiKey={googleMapsApiKey}>
              <GoogleMap mapContainerStyle={{ width: '1000px', height: '600px' }} zoom={16} center={{ lat: content.maps[0], lng: content.maps[1] }}>
                <Marker position={{ lat: content.maps[0], lng: content.maps[1] }} />
                {infoWindowOpen && (
                  <InfoWindow position={{ lat: content.maps[0] + 0.001, lng: content.maps[1] }} onCloseClick={() => setInfoWindowOpen(false)}>
                    <Box  sx={{ p: 1, backgroundColor: 'white', borderRadius: 1 }}>
                    <Typography variant="h6" sx={{ color: 'darkgreen' }}>{content.shop_name}</Typography>
                    </Box>
                  </InfoWindow>
                )}
              </GoogleMap>
            </LoadScript>
          </Box>
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.opening_times.title}</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <TableContainer component={Paper} sx={{ maxWidth: 250 }}>
              <Table sx={{ minWidth: 200 }} aria-label="opening times table">
                <TableBody>
                  {rowsOpeningTimes.map((row) => (
                    <TableRow key={row.day}>
                      <TableCell component="th" scope="row">{row.day}:</TableCell>
                      <TableCell align="right">{row.time}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', mt: 2, mb: 4 }}>{content.opening_times.holidays}</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Location;
