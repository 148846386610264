import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

const CookieConsentContainer = styled(Box)({
  position: 'fixed',
  bottom: 0,
  width: '100%',
  backgroundColor: '#333',
  color: '#fff',
  textAlign: 'center',
  padding: '1em',
  zIndex: 1000,
});

const CookieConsent = () => {
  const [visible, setVisible] = useState(true);

  const acceptCookies = () => {
    // Handle cookie acceptance (e.g., set a cookie, localStorage, etc.)
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <CookieConsentContainer>
      <Typography variant="body2">
        We use cookies to improve your experience on our site. By using our site, you accept our cookie policy.
      </Typography>
      <Button onClick={acceptCookies} sx={{ ml: 2 }} variant="contained" color="primary">
        Accept
      </Button>
    </CookieConsentContainer>
  );
};

export default CookieConsent;
