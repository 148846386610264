import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, Typography, useTheme, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = ({ language }) => {
  const theme = useTheme();
  const [footerContent, setFooterContent] = useState(null);

  useEffect(() => {
    const loadFooterContent = async () => {
      const response = await import(`../data/footer/${language}.json`);
      setFooterContent(response);
    };
    loadFooterContent();
  }, [language]);

  if (!footerContent) return <Typography>Loading...</Typography>;

  return (
    <AppBar position="static" sx={{ backgroundColor: theme.palette.background.banner, mt: 'auto', py: 2, borderTop: `1px solid ${theme.palette.divider}` }}>
      <Toolbar sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', mb: 1 }}>
          <Button color="inherit" component={Link} to="/about" sx={{ mx: 1 }}>{footerContent.about}</Button>
          <Button color="inherit" component={Link} to="/jobs" sx={{ mx: 1 }}>{footerContent.jobs}</Button>
          <Button color="inherit" component={Link} to="/contact" sx={{ mx: 1 }}>{footerContent.contact}</Button>
          <Button color="inherit" component={Link} to="/privacy" sx={{ mx: 1 }}>{footerContent.privacy}</Button>
        </Box>
        <Typography variant="body2" sx={{ color: '#fff', mt: 1 }}>{footerContent.created}</Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
