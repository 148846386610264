import { createTheme } from '@mui/material/styles';
import { amber } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#263a33',
    },
    secondary: {
      main: amber[500],
    },
    background: {
      banner:  '#102820',
      default: '#40534d',
      paper: '#263a33',
    },
    text: {
      primary: '#ffffff',
      secondary: '#aaaaaa',
    },
    divider: '#E6B560',
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.warning.main,
          color: theme.palette.warning.contrastText,
        }),
      },
    },
  },
});

export default theme;

