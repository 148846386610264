import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, useTheme} from '@mui/material';

const HolidayAccommodation = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/holiday_accommodation/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  if (!content) return <Typography>Loading...</Typography>;

  return (
    <Container>
    <Box sx={{ my: 4 }}>

      <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.title}</Typography>

      <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
        <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.paragraph1}</Typography>
        <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.paragraph2}</Typography>

        <Box
          component="img"
          sx={{
            width: '100%',
            maxWidth: 800,
            margin: '0 auto',
            display: 'block',
            borderRadius: '10px'
          }}
          alt="Activities"
          src="/images/out/walensee.jpg"
        />
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>

        {content.sections.map((section, index) => (
          <Box key={index} sx={{ textAlign: 'center', my: 2 }}>
            <Typography variant="h5" component="h2" gutterBottom>
              {section.title}
            </Typography>
            <Typography variant="body1" component="p">
              {section.content}
            </Typography>
          </Box>
        ))}
      </Box>

      <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
        <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.book_now.title}</Typography>
        <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.book_now.content} {content.book_now.link}</Typography>
      </Box>
    </Box>
  </Container>
  )
};

export default HolidayAccommodation;