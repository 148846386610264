const Languages = [
  { code: 'en', name: 'English', flag: 'GB' },
  { code: 'de', name: 'Deutsch', flag: 'DE' },
  { code: 'fr', name: 'Français', flag: 'FR' },
  { code: 'it', name: 'Italiano', flag: 'IT' },
  { code: 'es', name: 'Español', flag: 'ES' },
  { code: 'th', name: 'ไทย', flag: 'TH' },
  { code: 'cn', name: '中文', flag: 'CN' },
  { code: 'jp', name: '日本語', flag: 'JP' },
];

export default Languages;