import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box, IconButton, Menu, MenuItem, Typography, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import InstagramIcon from '@mui/icons-material/Instagram';
import Flag from 'react-world-flags';
import Languages from './Languages';

const Header = ({ setLanguage, language}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [spaMenuAnchorEl, setSpaMenuAnchorEl] = useState(null);
  const [hairMenuAnchorEl, setHairMenuAnchorEl] = useState(null);
  const [headerContent, setHeaderContent] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadHeaderContent = async () => {
      const response = await import(`../data/header/${language}.json`);
      setHeaderContent(response);
    };
    loadHeaderContent();
  }, [language]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageChange = (code) => {
    setLanguage(code);
    handleMenuClose();
  };

  const handleSpaMenuOpen = (event) => {
    setSpaMenuAnchorEl(event.currentTarget);
  };

  const handleSpaMenuClose = () => {
    setSpaMenuAnchorEl(null);
  };

  const handleHairMenuOpen = (event) => {
    setHairMenuAnchorEl(event.currentTarget);
  };

  const handleHairMenuClose = () => {
    setHairMenuAnchorEl(null);
  };

  const handleRequestPage = (service) => {
    setSpaMenuAnchorEl(null);
    setHairMenuAnchorEl(null);
    navigate('/' + service);
  };

  if (!headerContent) return <Typography>Loading...</Typography>;

  return (
    <AppBar position="static" sx={{ backgroundColor: theme.palette.background.banner }}>
      <Toolbar sx={{ flexDirection: 'column', alignItems: 'center' }}>
        <img src="/images/Logo.png" alt="Logo" style={{ minWidth: '100px', maxWidth: '400px', marginBottom: '20px' }} />
        <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
          <Button color="inherit" component={Link} to="/">
            {headerContent.home}
          </Button>

          <Button color="inherit" onClick={handleSpaMenuOpen}>
            {headerContent.massage.title}
          </Button>
          <Menu
            anchorEl={spaMenuAnchorEl}
            open={Boolean(spaMenuAnchorEl)}
            onClose={handleSpaMenuClose}
          >
            {Object.keys(headerContent.massage.submenus).map((service) => (
              <MenuItem key={service} onClick={() => handleRequestPage(service)}>
                {headerContent.massage.submenus[service]}
              </MenuItem>
            ))}
          </Menu>

          <Button color="inherit" onClick={handleHairMenuOpen}>
            {headerContent.hair_studio.title}
          </Button>
          <Menu
            anchorEl={hairMenuAnchorEl}
            open={Boolean(hairMenuAnchorEl)}
            onClose={handleHairMenuClose}
          >
            {Object.keys(headerContent.hair_studio.submenus).map((service) => (
              <MenuItem key={service} onClick={() => handleRequestPage(service)}>
                {headerContent.hair_studio.submenus[service]}
              </MenuItem>
            ))}
          </Menu>

          <Button color="inherit" component={Link} to="/location">
            {headerContent.location}
          </Button>
          
          <Button color="inherit" component={Link} to="/book_now">
            {headerContent.booking}
          </Button>

          <Button color="inherit" component={Link} to="/holiday_accommodation">
            {headerContent.holiday_accommodation}
          </Button>

          <IconButton
            edge="end"
            color="inherit"
            onClick={handleMenuOpen}
          >
            <LanguageIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {Languages.map((language) => (
              <MenuItem key={language.code} onClick={() => handleLanguageChange(language.code)}>
                <Flag code={language.flag} height="16" style={{ marginRight: '8px' }} />
                {language.name}
              </MenuItem>
            ))}
          </Menu>
          <IconButton color="inherit" href="https://www.instagram.com/rachinee_body_and_hair_spa?utm_source=qr&igsh=MWpmMHYwanU5ZXZybA==" target="_blank" sx={{ mx: 1 }}>
            <InstagramIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <Box sx={{ borderBottom: `1px solid ${theme.palette.divider}`, width: '100%' }} />
    </AppBar>
  );
};

export default Header;
