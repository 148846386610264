import React, { useState, useEffect } from 'react';
import ConsultationForm from '../components/ConsultationForm';
import { Container, Typography, Box, useTheme, Alert } from '@mui/material';

const BookNow = ({ language }) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);
  const [screen, setScreen] = useState('mailForm');
  const [mailResponse, setMailResponse] = useState('');

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/book_now/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  if (!content) return <Typography>Loading...</Typography>;

  const renderConfirmationScreen = () => (
    <Box sx={{ textAlign: 'center', my: 4 }}>
      <Alert severity="success">
        <Typography variant="h6" component="h3" gutterBottom>
          {content.successfully_sent}
        </Typography>
      </Alert>
    </Box>
  );

  const renderFailureScreen = () => (
    <Box sx={{ textAlign: 'center', my: 4 }}>
      <Alert severity="error">
        <Typography variant="h6" component="h3" gutterBottom>
          {content.failed_sent}
        </Typography>
      </Alert>
    </Box>
  );

  if (screen === 'noMailForm' && mailResponse === content.successfully_sent) {
    return renderConfirmationScreen();
  }

  if (screen === 'noMailForm' && mailResponse === content.failed_sent) {
    return renderFailureScreen();
  }

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
          {content.title}
        </Typography>
        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
            }}>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>
            {content.section1}
          </Typography>
          
          <ConsultationForm content={content} setMailResponse={setMailResponse} setScreen={setScreen} />
        </Box>
      </Box>
    </Container>
  );
};

export default BookNow;
