import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, useTheme } from '@mui/material';

const Privacy = ({language}) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/privacy/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  if (!content) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <Box sx={{ my: 4 }}>

        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.title}</Typography>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.introduction.title}</Typography>
          {content.introduction.sections.map((section, index) => (
              <Box key={index} sx={{ textAlign: 'center', my: 2 }}>
                <Typography variant="body1" component="p">{section}</Typography>
              </Box>
            ))}
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.fair_use_information.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.fair_use_information.description}</Typography>
          {content.fair_use_information.sections.map((section, index) => (
              <Box key={index} sx={{ textAlign: 'center', my: 2 }}>
                <Typography variant="body1" component="p">{section}</Typography>
              </Box>
            ))}
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.information.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.information.description}</Typography>
          {content.information.sections.map((section, index) => (
              <Box key={index} sx={{ textAlign: 'center', my: 2 }}>
                <Typography variant="body1" component="p">• {section}</Typography>
              </Box>
            ))}
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.information_sharing.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.information_sharing.description}</Typography>
          {content.information_sharing.sections.map((section, index) => (
              <Box key={index} sx={{ textAlign: 'center', my: 2 }}>
                <Typography variant="body1" component="p">{section}</Typography>
              </Box>
            ))}
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
            <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.data_security.title}</Typography>
            <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.data_security.description}</Typography>
          </Box>

          <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.privacy_rights.title}</Typography>
          {content.privacy_rights.sections.map((section, index) => (
              <Box key={index} sx={{ textAlign: 'center', my: 2 }}>
                <Typography variant="body" component="p">{section}</Typography>
              </Box>
            ))}
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.contact_us.title}</Typography>
          <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.contact_us.description}</Typography>
          {content.contact_us.sections.map((section, index) => (
              <Box key={index} sx={{ textAlign: 'center', my: 2  }}>
                <Typography variant="body1" component="p" fontStyle="italic">{section}</Typography>
              </Box>
            ))}
        </Box>

      </Box>

    </Container>
  );
};

export default Privacy;