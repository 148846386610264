import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, useTheme } from '@mui/material';

const Jobs = ({language}) => {
  const theme = useTheme();
  const [content, setContent] = useState(null);

  useEffect(() => {
    const loadContent = async () => {
      const response = await import(`../data/jobs/${language}.json`);
      setContent(response);
    };
    loadContent();
  }, [language]);

  if (!content) return <Typography>Loading...</Typography>;

  return (
    <Container>
      <Box sx={{ my: 4 }}>

        <Typography variant="h4" component="h1" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.title}</Typography>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.hairdresser.title}</Typography>
          {content.hairdresser.vacancies.length > 0 ?
          content.hairdresser.vacancies.map((section, index) => (
            <Box key={index} sx={{ textAlign: 'center', my: 2 }}>
              <Typography variant="h6" component="h3">{section.title}</Typography>
              <Typography variant="body1" component="p">{section.advertisement}</Typography>
              <Typography variant="body1" component="p">{section.skills}</Typography>
              <Typography variant="body1" component="p">{section.benefits}</Typography>
            </Box>
          )) : <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.no_vacancies}</Typography>
          }
        </Box>

        <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.massage.title}</Typography>
          {content.massage.vacancies.length > 0 ?
          content.massage.vacancies.map((section, index) => (
            <Box key={index} sx={{ textAlign: 'center', my: 2 }}>
              <Typography variant="h6" component="h3">{section.title}</Typography>
              <Typography variant="body1" component="p">{section.advertisement}</Typography>
              <Typography variant="body1" component="p">{section.skills}</Typography>
              <Typography variant="body1" component="p">{section.benefits}</Typography>
            </Box>
          )) : <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.no_vacancies}</Typography>
          }
        </Box>
      </Box>

      <Box
          sx={{
            textAlign: 'center',
            my: 4,
            p: 2,
            border: '1px solid',
            borderColor: theme.palette.divider,
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}>
          <Typography variant="h5" component="h2" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.beautician.title}</Typography>
          {content.beautician.vacancies.length > 0 ?
          content.beautician.vacancies.map((section, index) => (
            <Box key={index} sx={{ textAlign: 'center', my: 2 }}>
              <Typography variant="h6" component="h3">{section.title}</Typography>
              <Typography variant="body1" component="p">{section.advertisement}</Typography>
              <Typography variant="body1" component="p">{section.skills}</Typography>
              <Typography variant="body1" component="p">{section.benefits}</Typography>
            </Box>
          )) : <Typography variant="body1" component="p" gutterBottom sx={{ textAlign: 'center', my: 2 }}>{content.no_vacancies}</Typography>
          }
        </Box>

    </Container>
  );
};

export default Jobs;